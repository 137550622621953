/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  window.localStorage.setItem(name, JSON.stringify(content))
}

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return

  let obj = window.localStorage.getItem(name)
  let content
  try {
    obj = JSON.parse(obj)
  } catch {
    return obj
  }
  if (typeof obj === 'string') {
    content = obj
  } else if (typeof obj === 'number') {
    content = Number(obj)
  } else if (typeof obj === 'boolean') {
    // eslint-disable-next-line no-eval
    content = eval(obj)
  } else if (typeof obj === 'object') {
    content = obj
  }
  return content
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}
