import { menuDefault } from '@/config'
import { setStore, getStore } from '@/util/storage'

const common = {
  state: {
    menuDefault: menuDefault,
    isCollapse: false,
    themeName: getStore('themeName') || 'theme-default'

  },
  mutations: {
    SET_COLLAPSE: (state) => {
      state.isCollapse = !state.isCollapse
      console.log(state.isCollapse)
    },
    SET_THEME_NAME: (state, themeName) => {
      state.themeName = themeName
      setStore('themeName', state.themeName)
    }
  }
}
export default common
