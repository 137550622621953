const title = '鲜十娘门店管理'

// 同时展示两级菜单
const useSecMenu = false

const storageKey = {
  userInfo: 'userInfo',
  permission: 'permission',
  menu: 'menu',
  token: 'token'
}

const aliOss = {
  region: 'oss-cn-shanghai',
  endpoint: 'oss-cn-shanghai.aliyuncs.com',
  accessKeyId: 'LTAI5tNK3n5grRTeYvjh34JK',
  accessKeySecret: 'I8hYtY72jNsoqL7za5mMpL3iHLlz8e',
  bucket: 'zhsn-store'
}

// 配置菜单的属性
const menuDefault = {
  iconDefault: 'iconfont icon-caidan',
  props: {
    label: 'name',
    path: 'path',
    icon: 'icon',
    children: 'children'
  }
}

const config = {
  development: {
    // baseUrl: 'http://172.16.2.73:6655/admin'
    baseUrl: 'http://localhost:6655/admin'
    // baseUrl: 'http://s1.zcondigit.com:7788/admin'
  },
  s1: {
    baseUrl: 'http://s1.zcondigit.com:7788/admin'
  },
  production: {
    baseUrl: 'https://store.zcondigit.com/admin'
  }
}
const env = process.env
console.log('=================')
console.log(env)
console.log('=================')
const baseUrl = config[env.NODE_ENV].baseUrl

export {
  aliOss,
  storageKey,
  title,
  useSecMenu,
  baseUrl,
  menuDefault
}
