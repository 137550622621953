<template>
  <div class="basic-container">
    <el-card>
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'BasicContainer'
}
</script>

<style lang="scss">
.basic-container {
  height: calc(100%);
  padding: 10px 6px;
  border-radius: 10px;
  box-sizing: border-box;

  .el-card {
    height: 100%;
    overflow: auto;
  }

  &:first-child {
    padding-top: 0;
  }
}
</style>
