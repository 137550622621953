const getters = {
  // user 相关信息
  userInfo: state => state.user.userInfo,
  token: state => state.user.token,
  roles: state => state.user.roles,
  permission: state => state.user.permission,
  menu: state => state.user.menu,
  menuAll: state => state.user.menuAll,
  menuDefault: state => state.common.menuDefault,
  isCollapse: state => state.common.isCollapse,
  themeName: state => state.common.themeName
}
export default getters
