import Vue from 'vue'
import { getToken } from '@/util/auth'
import store from '../store'
import VueRouter from 'vue-router'
import PageRouter from './page/'
import ViewsRouter from './views/'
import vueRouter from './vue-router'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
// 调用回调方法 让浏览器不再报出这个错误
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export const createRouter = () => new VueRouter({
  mode: 'history',
  routes: [...PageRouter, ...ViewsRouter]
})

const router = createRouter()
vueRouter.install(Vue, router, store)
router.$vueRouter.formatRoutes(store.state.user.menu, true)

// 重置路由
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  vueRouter.install(Vue, router, store)
}

const whiteList = ['/login'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  const token = getToken()
  if (token) {
    // 如果已经登录，则直接跳转到首页
    if (to.path === '/login') {
      next('/')
    }
    // 如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
    if (store.getters.token.length === 0) {
      next({ path: '/login' })
    } else {
      // 否则放行
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach((to, from) => {
  NProgress.done()
  const title = to.query.name || to.name
  // 根据当前的标签也获取label的值动态设置浏览器标题
  router.$vueRouter.setTitle(title)
})

export default router
