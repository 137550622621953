<template>
  <div class="vue-top">
    <div class="top-bar__left">
      <div
          v-if="!useSecMenu"
          class="vue-breadcrumb"
          :class="[{ 'vue-breadcrumb--active': isCollapse }]">
        <i
            class="icon-navicon"
            @click="setCollapse"></i>
      </div>
    </div>
    <div class="top-bar__right">
      <el-tooltip
          effect="dark"
          content="主题"
          placement="bottom">
        <div class="top-bar__item top-bar__item--show">
          <top-theme></top-theme>
        </div>
      </el-tooltip>
      <el-menu class="navbar" mode="horizontal">
        <el-dropdown>
        <span class="el-dropdown-link">
          {{ userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="showDiaLog" style="display:block;">修改密码</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="logout" style="display:block;">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu>
      <el-dialog title="修改密码" :visible.sync="dialogVisible" size="tiny" style="position:fixed;top: 0; left: 0; right: 0; bottom: 0;z-index: 99999999;">
        <el-form :model="info" label-width="100px" :rules="addRules">
          <el-form-item label="原始密码" prop="oldPwd">
            <el-input style="width:300px" v-model="info.oldPwd" type="password" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd">
            <el-input style="width:300px" v-model="info.newPwd" type="password" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="newPwdAg">
            <el-input style="width:300px" v-model="info.newPwdAg" type="password" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="resetPassword">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { validateNull } from '@/util/validate'
import { useSecMenu } from '@/config'
import { changePassword } from '@/api/sys/user'
import topTheme from './top-theme'

export default {
  components: {
    topTheme
  },
  name: 'Top',
  data () {
    return {
      dialogVisible: false,
      info: {
        oldPwd: '',
        newPwd: '',
        newPwdAg: ''
      },
      addRules: {
        newPwd: [{ required: true, message: '请输入密码', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const passwordreg = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/
              if (!passwordreg.test(value)) {
                callback(new Error('密码必须大于8位，由大小写字母、数字、特殊字符组成'))
              } else {
                callback()
              }
            }
          }],
        newPwdAg: [{ required: true, message: '请确认密码', trigger: 'blur' }],
        oldPwd: [{ required: true, message: '请输入原始密码', trigger: 'blur' }]
      },
      useSecMenu: useSecMenu
    }
  },
  filters: {},
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapState({}),
    ...mapGetters([
      'userInfo',
      'isCollapse'
    ])
  },
  methods: {
    resetPassword () {
      if (this.info.newPwd !== this.info.newPwdAg) {
        this.$message({
          message: '两次新密码不一致',
          type: 'warning'
        })
      } else {
        changePassword(this.info.oldPwd, this.info.newPwd).then(res => {
          console.log(res)
          if (res.success) {
            this.dialogVisible = false
            // 修改成功后重新登录
            this.$store.dispatch('LogOut').then(() => {
              this.$router.push({ path: '/login' })
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }
    },
    cancel () {
      this.dialogVisible = false
      this.info = {}
    },
    showDiaLog () {
      this.dialogVisible = true
    },
    validateNull (val) {
      return validateNull(val)
    },
    setCollapse () {
      this.$store.commit('SET_COLLAPSE')
    },
    goInfo () {
      this.$router.push('/')
    },
    logout () {
      this.$confirm('退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/login' })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
  margin-bottom: 25px;
}
.top-bar__right {
  span {
    font-size: 15px;
    margin-right: 5px;
  }

}
</style>
